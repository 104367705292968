import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtecapivari.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3510401',
  schema: 'pbi_dte_capivari',
  groupId: '5a113933-1b40-4f84-b46b-09acc71f68b6',
  analiseGeral: 'b0995700-3256-4eb5-b894-c9a837555e9b',
  analiseUsuarios: '9fb0079c-efb5-45fa-808c-c5e61d63b230',
  analiseComunicados: '7a0bd269-023e-4b52-a191-6ae17d4ee89e',
  analiseProcurador: 'e993fcab-1846-422c-b2d6-cc09fea67e18',
  analiseEventos: '433a9d7f-d6a4-47ae-aa00-21d489383cbc'
};
